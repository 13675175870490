import { render, staticRenderFns } from "./ball.vue?vue&type=template&id=2092abc2&scoped=true"
import script from "./ball.vue?vue&type=script&lang=js"
export * from "./ball.vue?vue&type=script&lang=js"
import style0 from "./ball.vue?vue&type=style&index=0&id=2092abc2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.98.0__lodash@4._97b9e0139818914e51daadce5bfd55fe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2092abc2",
  null
  
)

export default component.exports